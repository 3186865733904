import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';
import { motion } from 'framer-motion';
import cx from 'classnames';

import Close from '@svg/close.svg';
import PortalModal from '@components/ui/portalModal';

import { useTracking } from '@lib/tracking';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const EnquiryForm = ({
  linkText,
  style = 'solid',
  enquiredFrom = 'Contact',
  activePage = 'Home Page',
  className,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { identify } = useTracking();
  return (
    <>
      <button
        className={cx(`${className}`, {
          'btn-light-red': style === 'light',
          'btn-solid-red': style === 'solid',
          'btn-red': style === 'outline',
          'text-link': style === 'text',
        })}
        onClick={() => setOpen(true)}
      >
        {linkText || 'Enquire Now'}
      </button>
      <PortalModal isShowing={isOpen}>
        <div className="px-gutter self-start lg:self-center w-full">
          <div className="p-10 max-w-7xl w-full bg-red-light rounded-xl mx-auto relative lg:px-20 lg:py-16">
            <button className="absolute top-4 right-4" onClick={() => setOpen(false)}>
              <Close className="w-16 text-red fill-current" />
            </button>
            <Formik
              initialValues={{
                fullName: '',
                email: '',
                phone: '',
                referral: '',
                message: '',
                enquiredFrom,
                activePage,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.fullName) {
                  errors.fullName = 'Full Name is Required';
                }
                if (!values.email) {
                  errors.email = 'Email address is Required';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = 'Invalid email address';
                }
                if (!values.phone) {
                  errors.phone = 'Contact Number is Required';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                identify(values.email);
                fetch(pathname || '/', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: encode({
                    'form-name': `contact-form`,
                    ...values,
                  }),
                })
                  .then(() => {
                    setSubmitting(false);
                    setStatus('success');
                  })
                  .catch((error) => alert(error));
              }}
            >
              {({
                onChange,
                isSubmitting,
                status,
                values,
                /* and other goodies */
              }) => (
                <Form
                  name="contact-form"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  subject="Website Enquiry"
                >
                  {/* Hidden Fields for netlify */}
                  <input type="hidden" name="form-name" value="contact-form" />
                  <input type="hidden" name="enquiredFrom" value={enquiredFrom} />
                  <input type="hidden" name="page" value={activePage} />
                  <p hidden>
                    <label>
                      <input name="bot-field" onChange={onChange} />
                    </label>
                  </p>
                  {status === 'success' ? (
                    <div className="col-span-full text-left">
                      <motion.h2
                        className="font-display text-2xl"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                      >
                        Thank you we will be in touch.
                      </motion.h2>
                    </div>
                  ) : (
                    <>
                      <div className="grid grid-cols-2 gap-10 items-start">
                        <div className="col-span-2 text-center">
                          <h2 className="text-center blockH0 text-red">Get in touch</h2>
                          <p className="text-lg max-w-lg mx-auto lg:text-xl">
                            Find out how we can become your partner and put your brand at the centre
                            of ours.{' '}
                          </p>
                        </div>
                        <div className="w-full">
                          <Field
                            type="text"
                            name="fullName"
                            placeholder="Full Name"
                            className="standard-input"
                          />
                          <ErrorMessage name="fullName" className="text-sm my-2" component="div" />
                        </div>
                        <div>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="standard-input"
                          />
                          <ErrorMessage name="email" className="text-sm my-2" component="div" />
                        </div>
                        <div>
                          <Field
                            type="text"
                            name="phone"
                            placeholder="Contact Number"
                            className="standard-input"
                          />
                          <ErrorMessage name="phone" className="text-sm my-2" component="div" />
                        </div>
                        <Field
                          type="text"
                          name="referral"
                          placeholder="How did you hear about us ?"
                          className="standard-input"
                        />
                        <Field
                          type="text"
                          name="comments"
                          placeholder="Comments"
                          component="textarea"
                          className="standard-input col-span-2"
                        />
                        <div className="flex justify-center col-span-2">
                          <button className="btn-red" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </PortalModal>
    </>
  );
};

export default EnquiryForm;
